.grid {
  height: 100%;
  .chatHeaderBox {
    // display: flex;
    // align-items: flex-end;
    .setBtnWithExpand{
      display: flex;
      .expandBtn{
        margin-left: 10px;
        span{
          margin: 0px;
          svg{
            font-size: 30px;
          }

        }
      }
    }
  }
  .layout {
    width: 100%;
    height: 100%;
    // height: 700px;
    // max-height: 650px;
    // border: 1px solid var(--color-slate-3);
    border-radius: 12px;
    // margin-top: 17px;
    // background-color: #f7f8f9;
    display: 'flex';
    flex-direction: 'row';
    .msg-sidebox{
      width: 350px;
    }
    .contentSec {
      // padding: 15px 0px 15px 15px;
      border: 1px solid var(--color-slate-3);
      background-color: var(--color-white);
      height: 100%; 
      border-radius: 12px;
      min-width: 278px;
      max-width: 278px;
      // width: 100%;
      .chatLayout {
        height: calc(100% - 96px);
        margin: 0px 5px;
        // margin-top: 10px;
        // border-radius: 6px;
        // overflow: hidden;
        // background-color: var(--color-white);
        // box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
        .userlist {
          height: calc(100% - 95px);
          // border: 1px solid var(--color-info);
          background-color: var(--color-white);
          border-radius: 12px;
          .listHeader {
            height: 36px;
            padding: 5px 8px;
            border-bottom: 1px solid var(--color-info);
            .title {
              font-size: 16px;
              font-family: Montserrat600;
              color: #000;
            }
          }
          .content {
            overflow-y: auto;
            height: calc(100% - 37px);
          }
        }
        .notFound{
          text-align: center;
          font-weight: bold;
          position: relative;
          top: 50%;
        }
        // .chatContent {
        //   height: calc(100% - 56px);
        //   border: 1px solid var(--color-info);
        //   background-color: var(--color-white);
        //   border-radius: 8px;
        // }
      }
    }

    .imgSec {
      height: 100%;
      flex-grow: 1;
      margin-left: 10px;
      .banner {
        height: 100%;
        border-radius: 10px;
        overflow: hidden;
        img {
          height: 100%;
          width: 100%;
          object-fit: cover;
        }
      }
    }
  }
  .imagePreview{
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    position: fixed;
    z-index: 20;
    .cross{
      font-size: 15;
      text-align: center;
      color: #fff;
      background: #00000050;
      padding: 1px 8px;
      cursor: pointer;
      top: 6px;
      right: 6px;
      position: absolute;
    }
  }
}
.shag {
  padding: 6px;
  border-radius: 8px;
  background-color: var(--color-white);
  border: 1px solid var(--color-info);
  margin-bottom: 10px;
  .head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .titleSec {
      display: flex;
      align-items: center;
      h5 {
        font-size: 14px;
        font-family: Montserrat600;
        color: var(--dark);
      }
      .tag {
        height: 17px;
        // width: 17px;
        border-radius: 2px;
        padding: 0 2px;
        font-size: 9px;
        line-height: unset;
        margin: 0 4px;
      }
      p {
        font-size: 10px;
      }
    }
    .date {
      font-size: 11px;
      color: #707070;
      font-family: Montserrat500;
    }
  }
  .item {
    display: flex;
    align-items: center;
    h6 {
      font-size: 12px;
      color: #000;
      flex: 1;
      margin-left: 5px;
      font-family: Montserrat500;
    }
  }
  .divider {
    margin: 5px 0;
  }
  .footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .user {
      font-size: 10px;
      color: #707070;
      span {
        font-size: 12px;
        color: #000;
        font-family: Montserrat600;
        margin-left: 3px;
      }
    }
  }
}

/*----------Chat----------*/
.chaContent {
  height: 100%;
  // background-color: var(--color-white);
  border-radius: 8px;
  // border: 1px solid var(--color-info);
  .chatHeader {
    height: 36px;
    display: flex;
    align-items: center;
    padding: 5px 5px;
    border-bottom: 1px solid var(--color-info);
    h5 {
      font-size: 14px;
      font-family: Montserrat600;
      color: #000;
    }
    p {
      font-size: 11px;
      font-family: Montserrat600;
      color: var(--color-slate-4);
      margin-left: 10px;
    }
  }
  .conversation {
    height:calc(100% - 35px);
    // min-width: 300px;
    // padding: 5px 5px;
    overflow-y: scroll;
    // display: block;
    // &::after {
    //   content: "";
    //   height:calc(100% - 35px);
    //   display: flex;
    //   align-items: flex-end;
    // }
    &::-webkit-scrollbar {
      width: 0px;
    }
    &::-webkit-scrollbar-track {
      // box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    }
    &::-webkit-scrollbar-thumb {
      // background-color: var(--color-info);
    }
    &.admin{
      height: 100%;
    }
  }

  .notificationConversation{
    height: calc(100% - 5px);
  }
  .list {
    height: calc(100% - 36px);
    overflow-y: scroll;
  }
  .typeMsg {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 5px 5px;
    :hover{
    .ant-input-affix-wrapper{
        border-color: var(--color-black)
      }
    }
    .sendBtn{
      width:30px;
      height:30px;
      background-color:var(--color-black-2);
      padding:0px;
      &:hover{
        background-color: var(--color-black);
        svg{
          border-radius:8px;
          // font-size: 20px;
          color: #fff;
        }
      }
    }
    .sendMsgInput{
      &:hover,&:focus{
        border-color: var(--color-black);
        outline: none;
        box-shadow: none;
      }
    }
  }
  .showNew {
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      position: absolute;
      background: #c4c4c4;
      bottom: 45px;
      right: 4px;
      padding: 5px;
      border-radius: 50%;
      &.admin{
        bottom: 15px;
        right: 4px;
      }
    }
}

/*----------Msg----------*/

.sender {
  margin-left: auto;
}
.receiver {
  margin-right: auto;
}
.msg {
  display: flex;
  align-items: self-end;
  word-break: break-all;
  width: max-content;
  min-width: 55%;
  max-width: 90%;
  margin-bottom: 13px;
  .items {
    width: 100%;
    display: flex;
    flex-direction: column-reverse;
    .text {
      width: 100%;
      padding: 3px 5px;
      background-color: #0162ea29;
      border-radius: 6px;

      p {
        font-size: 10px;
        font-family: Montserrat500;
        color: #000;
      }
    }
    .image{
      background: transparent;
      width: 150px;
      img{
        object-fit: cover;
      }
    }
    .foot {
      display: flex;
      justify-content: space-between;
      p {
        font-size: 8px;
        font-family: Montserrat500;
        color: #707070;
      }
    }
  }
}
/*----------Msg----------*/

/*----------User----------*/

.user {
  padding: 6px 12px;
  border-bottom: 1px solid var(--color-slate-2);
  &:hover{
    background-color: var(--color-slate-2);
  }
  &:active{
    background-color: var(--color-slate-2);
  }
  .msg{
    font-size: 14px;
    color: #00000099;
    margin: 0;
  }
  .items {
    display: flex;
    justify-content: space-between;
    h6 {
      font-size: 14px;
      font-family: Montserrat600;
      color: #000;
      span {
        font-size: 10px;
        color: var(--color-primary);
        text-transform: uppercase;
        font-family: Montserrat500;
      }
    }
    .caption {
      display: flex;
      align-items: center;
      .badge{
        display: flex;
        justify-content: center;
        height: 16px;
        width: 16px;
        padding: 0;
        line-height: unset;
        font-size: 10px;
      }
      .time {
        font-size: 10px;
        color: var(--color-slate-4);
      }
    }
  }
}

/* -------------------------------------------------------------------------- */
/*                            RESIZE ICON FOR PANLE                           */
/* -------------------------------------------------------------------------- */

.ResizeHandleOuter {
  width: 80%;
  flex: 0 auto;
  position: relative;
  outline: none;
  margin: 0px auto;
  --background-color: transparent;
}
.ResizeHandleOuter:active {
  .ResizeHandleInner {
    background-color: var(--color-dark);
  }
      .Icon{
          path{
            fill: #fff;
          }
      }
}


.ResizeHandleInner {
  position: absolute;
  top: 0.25em;
  bottom: 0.25em;
  left: 0.25em;
  right: 0.25em;
  border-radius: 0.25em;
  background-color: var(--color-slate-5);
  transition: background-color 0.2s linear;
  // width: 16px;
  
}
.Icon {
  width: 12px;
  height: 12px;
  position: absolute;
  left: calc(50% - 6px);
  top: calc(50% - 6px);
}