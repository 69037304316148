.invoiceTable {
  table {
    width: 100%;
    tr:first-child{
      border-top: 1px solid var(--color-text-5);
    }
    tr:not(:last-child) {
      border-top: 1px solid var(--color-text-5);
    }
    tr.payment{
      border-top: 1px solid var(--color-text-5);
    }
    tr {
        font-family: montserrat500;
        color: var(--color-dark-blue);
      :nth-child(n + 2) {
        text-align: center;
      }
      th {
        padding: 7px;
        font-size: 12px;
        font-family: montserrat600;
        color: var(--color-text-5);
        text-transform: uppercase;
        border: none;
      }
      td {
        padding: 4px 7px;
      }
    }
    .trHeighlight.invoice :nth-child(n + 2) {
      background-color: var(--color-dark-blue);
      color: var(--color-white);
      padding: 10px 7px;
    }
    .trHeighlight.payment :nth-child(n + 2) {
      background-color: var(--color-dark-blue);
      color: var(--color-white);
      padding: 10px 7px;
    }
  }
}
.paymentReceipt{
  width: 90%;
  margin: 67px auto 0;
  text-align: center;
  border: 1px solid #000;
  border-radius: 4px;
  padding: 9px;
}
.modalFooter{
  border-top: 3px solid #0000001F;
  padding-top: 12px;
  justify-content: space-between;
  align-items: center;
  
}