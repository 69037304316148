.NoData{
  padding-top: 44px;
}
.YardMoveTable {
  background-color: transparent;
  border: unset;
  .ant-spin-nested-loading {
    .ant-spin-container {
        .ant-table {
            background-color: var(--color-white) !important;
            border-radius: 12px;
            .ant-table-container{
                border-radius: 12px;
                border: 2px solid var(--color-slate-3);
            .ant-table-body{
                table{
                    tbody{
                        tr{
                            &:last-child{
                                border-bottom-left-radius: 12px;
                                border-bottom-right-radius: 12px;
                                td{
                                    &:first-child{
                                        border-bottom-left-radius: 12px;
                                    }
                                    &:last-child{
                                        border-bottom-right-radius: 12px;

                                    }

                                }
                            }
                        }
                    }
                }
            }
        }
      }
      .reduceSize {
        .ant-pagination-item {
            height: auto;
            width: auto;
            line-height: 24px;
        }
        .ant-pagination-options{
          .ant-pagination-options-size-changer{
            .ant-select-selector{
              height: 24px !important;
              .ant-select-selection-item{
                line-height: 24px !important;

              }
            }
          }
        }
      }
    }
  }
  th {
    border-top: unset !important;
  }
}
.yard-move-clone-row {
  position: relative;
  .yard-move-tabs-col {
    .yard-move-tabs-box {
      display: flex;
      position: absolute;
      z-index: 2;
      top: 8px;
      .ant-btn {
        box-shadow: none;
        outline: none;
        padding-bottom: 0px;
        padding-top: 0px;
        height: 35px !important;
        span{
            text-shadow: none;
        }
      }
    }
  }
}

.expandButton{
  padding-top: 2px;
  &.black{
    span{
      background-color: var(--color-slate-1);
      svg{
        color: var(--color-black);
      }
    }
    &:hover{
      span{
        background-color: var(--color-gray);
        svg{
          color: var(--color-white);
        }
      }

    }
  }
  &.white{
    span{
      background-color: var(--color-black-2);
      svg{
        color: var(--color-white);
      }
    }
    &:hover{
      span{
        background-color: var(--color-gray);
        svg{
          color: var(--color-white);
        }
      }

    }
  }
  span{
    display: block;
    width: max-content;
    padding: 3px;
    background-color: var(--color-slate-3);
    font-size: 16px;
    cursor: pointer;
    border-radius: 0.25em;
    svg{
      width: 100%;
    display: block;
    font-size: 22px;
    }

  }
}
// .resizehandleWithExpand{
//   display: flex;
//   span{
//     width: 9%;
//     background-color: var(--color-slate-3);
//     font-size: 18px;
//     cursor: pointer;
//     margin: 0.25em auto;
//     border-radius: 0.25em;
//     svg{
//       width: 100%;
//     display: block;
//     font-size: 24px;
//     }

//   }
// }
.head{
  position: relative;
  // margin-bottom: 12px;
  .clearFilterRow{
    position: absolute;
    justify-content: end;
    right: 30px;
    top: 10px;
  }
}
.PanelHeighetCheck{
 margin-bottom: 10px;
}