.content{
    .user{
        margin: 0 0 1.25rem 0;
        width: 100%;
    }
    .table{
        border-collapse: collapse;
        width: 100%;
        thead{
            background-color: #F7F8F9;
            tr th{
                padding: 10px;
            }
            &::after {
                content: "";
                display: block;
                background-color: #EBEEF3;
                height: .8rem;
                width: 100%;
            }
        }
        tbody tr {
            margin: 0 10px;
            td {   
                background-color: #F7F8F9;
                border-bottom: 0.5px solid rgba(0, 0, 0, 0.12);
                padding: 10px;
            }
        }
    }
}