.timeSheetBox{
    background-color: var(--color-white);
    padding: 12px 15px;
    border-radius: 12px;
    border: 1px solid var(--color-slate-3);   
    margin-bottom: 20px;
    .datefield{
        width: 70%;
    }
}
