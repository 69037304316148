.overlay{
    min-width: 100%;
    height: 100dvh;
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    left: 0;
    z-index:30;
    background-color:#222222a8;
    .overlayInner{
        min-width: 40%;
        height: 40dvh;
        z-index: 20;
        position: relative;
        color: #fff;
        .dragger{
            cursor: default;
            padding: 48px;
            border: 2px dashed #fff;
            text-align: center;
            background-color: #222;
            color: #fff;
            .draggerTitle{
                p{
                    font-size: 24px;
                    margin-bottom: 24px;
                    color: #fff;
                }
            }
            .fileSection{
                cursor: default;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                height: 100%;
                color: #fff;
                .fileSectionIcons{
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        color: #fff;
                        width: 40%;
                        cursor: pointer;
                        font-size: 42px;
                }
            }
        }
    }
}