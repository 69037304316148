*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--color-dark);
  font-family: montserrat400;
  background: var(--color-background);
 
  // background: linear-gradient(
  //   180deg,
  //   rgba(203, 213, 225, 0.6) 0%,
  //   rgba(255, 255, 255, 0) 100%
  // );
}
.not-scroll-body::-moz-scrollbar {
  display: none; 
}
@font-face {
  font-family: montserrat300;
  src: url("./Assets/fonts/static/Montserrat-Light.ttf");
}
@font-face {
  font-family: montserrat400;
  src: url("./Assets/fonts/static/Montserrat-Regular.ttf");
}
@font-face {
  font-family: montserrat500;
  src: url("./Assets/fonts/static/Montserrat-Medium.ttf");
}
@font-face {
  font-family: montserrat600;
  src: url("./Assets/fonts/static/Montserrat-SemiBold.ttf");
}
@font-face {
  font-family: montserrat700;
  src: url("./Assets/fonts/static/Montserrat-Bold.ttf");
}

p {
  margin-bottom: 0px;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

p, span, h1,h2,h3,h4,h5,h6, button{
  user-select: none;
} 

:root {
  --color-background: #f2f2f2;
  --color-primary-dark: #1e3a65;
  --color-dark-blue: #222234;
  --color-primary: #0055cc;
  --color-primary-light: #0162ea;
  --color-white: #fff;
  --color-dark-80: #222222;
  --color-dark: #111827;
  --color-dark-shade: #111827;
  --color-info-60: #00000099;
  --color-info-40: #00000040;
  --color-info-50: #00000050;
  --color-info: #d1d5db;
  --color-info-light: #cbd5e1;
  --color-light: #00000038;
  --color-slate-1: #d9d9d9;
  --color-slate-2: #e7eaee;
  --color-slate-3: #d0d5dd;
  --color-slate-4: #a0abbb;
  --color-slate-5: #ccd2e3;
  --color-success: #10b981;
  --color-success-dark: #047857;
  --color-success-dark-1: #005c42;
  --color-danger: #ef4444;
  --color-danger-1: #da3232;

  /* -------------------------------------------------------------------------- */
  /*                              DARK THEME COLOR                              */
  /* -------------------------------------------------------------------------- */
  --color-black: #000;
  --color-black-2: #222222;
  --color-tabCount: #e6e6e6;
  --color-gray: #86878a;
  --color-gray-2: #6b7280;
  --color-text: #d8d8d8;
  --color-text-2: #86878a;
  --color-text-3: #586066;
  --color-text-4: #7a7a7a;
  --color-text-5: #667085;
  --color-error: #ff4d4f;
}

/*-----------------------------*/
/*          Container          */
/*-----------------------------*/
.container {
  margin-left: auto;
  margin-right: auto;
  padding: 1rem;
  @media only screen and (min-width: 1400px) {
    max-width: 1320px;
  }
  @media only screen and (max-width: 1200px) {
    max-width: 1140px;
  }
  @media only screen and (max-width: 992px) {
    max-width: 960px;
  }
  @media only screen and (max-width: 768px) {
    max-width: 720px;
  }
  @media only screen and (max-width: 576px) {
    max-width: 520px;
  }
}
$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px,
  xxl: 1320px,
);
/*-----------------------------*/
/*          Typography         */
/*-----------------------------*/

.text-2xs {
  font-size: 0.625rem;
  line-height: 0.65rem;
}
.text-xs {
  font-size: 0.75rem;
  line-height: 1rem;
}
.text-sm {
  font-size: 0.875rem;
  line-height: 1.25rem;
}
.text-base {
  font-size: 1rem;
  line-height: 1.5rem;
}
.text-lg {
  font-size: 1.125rem;
  line-height: 1.75rem;
  // @media only screen and (max-width: 1280px) {
  //   font-size: 0.875rem;
  // }
}
.text-xl {
  font-size: 1.25rem;
  line-height: 1.75rem;
  @media only screen and (max-width: 1280px) {
    font-size: 1rem;
  }
}
.text-2xl {
  font-size: 1.5rem;
  line-height: 2rem;
}
.text-3xl {
  font-size: 1.875rem;
  line-height: 2.25rem;
}
.text-4xl {
  font-size: 2.25rem;
  line-height: 2.5rem;
  @media only screen and (max-width: 1280px) {
    font-size: 1.875rem;
  }
}
.text-4xxl {
  font-size: 2rem;
  line-height: 2rem;
  @media only screen and (max-width: 1280px) {
    font-size: 1.875rem;
  }
}
.text-5xl {
  font-size: 3rem;
  line-height: 3rem;
}
.text-6xl {
  font-size: 3.75rem;
  line-height: 3.75rem;
}
.text-7xl {
  font-size: 4.5rem;
  line-height: 4.5rem;
}
.text-8xl {
  font-size: 6rem;
  line-height: 6rem;
}
.text-9xl {
  font-size: 8rem;
  line-height: 8rem;
}

/*-----------------------------*/
/*          Typography         */
/*-----------------------------*/
/*-----------------------------*/
/*            Colors           */
/*-----------------------------*/

.primary {
  color: var(--color-primary);
}
.primary-dark {
  color: var(--color-primary-dark);
}
.dark-80 {
  color: var(--color-dark-80);
}
.dark {
  color: var(--color-dark);
}
.white {
  color: var(--color-white);
}
.info {
  color: var(--color-info-60);
}
.info-light {
  color: var(--color-info-50);
}
.text-2 {
  color: var(--color-text-2);
}
.text-5 {
  color: var(--color-text-5);
}
.success {
  color: var(--color-success);
}
.success-dark {
  color: var(--color-success-dark);
}
.danger {
  color: var(--color-danger);
}
/*-----------------------------*/
/*            Colors           */
/*-----------------------------*/
/*-----------------------------*/
/*            Fonts            */
/*-----------------------------*/

.font-700 {
  font-family: montserrat700;
}
.font-600 {
  font-family: montserrat600;
}
.font-500 {
  font-family: montserrat500;
}
.font-300 {
  font-family: montserrat300;
}

/*-----------------------------*/
/*            Fonts            */
/*-----------------------------*/
/*-----------------------------*/
/*       Height / Width        */
/*-----------------------------*/
.w-12 {
  width: 12%;
}
.w-40 {
  width: 40%;
}
.w-50 {
  width: 50%;
}
.w-60 {
  width: 60%;
}
.w-70 {
  width: 70%;
}
.w-80 {
  width: 80%;
}
.w-90 {
  width: 90%;
}
.w-100 {
  width: 100%;
}
.h-100 {
  height: 100%;
}
/*-----------------------------*/
/*       Height / Width        */
/*-----------------------------*/
/*-----------------------------*/
/*           spacing           */
/*-----------------------------*/
.m-5 {
  margin: 5px;
}
.mr-auto {
  margin: auto;
}
.mr-3 {
  margin-right: 3px;
}
.mr-5 {
  margin-right: 5px;
}
.mr-8 {
  margin-right: 8px;
}
.mr-10 {
  margin-right: 10px;
}
.mr-20 {
  margin-right: 20px;
}
.ml-auto {
  margin-left: auto;
}
.ml-3 {
  margin-left: 3px;
}
.ml-5 {
  margin-left: 5px;
}
.ml-8 {
  margin-left: 8px;
}
.ml-10 {
  margin-left: 10px;
}
.ml-20 {
  margin-left: 20px;
}
.ml-5 {
  margin-left: 5px;
}
.mx-0 {
  margin-left: 0 !important;
  margin-right: 0 !important;
}
.my-15 {
  margin-top: 15px;
  margin-bottom: 15px;
}
.my-10 {
  margin-top: 10px;
  margin-bottom: 15px;
}
.my-25 {
  margin-top: 25px;
  margin-bottom: 25px;
}
.mt-5 {
  margin-top: 5px;
}
.mt-15 {
  margin-top: 15px;
}
.mt-20 {
  margin-top: 20px;
}
.mt-25 {
  margin-top: 25px;
}
.mt-30 {
  margin-top: 30px;
}
.mt-45 {
  margin-top: 45px;
}
.mb-5 {
  margin-bottom: 5px;
}
.mb-10 {
  margin-bottom: 10px;
}
.mb-15 {
  margin-bottom: 15px;
}
.mb-20 {
  margin-bottom: 20px;
}
.mb-25 {
  margin-bottom: 25px;
}
.p-0 {
  padding: 0;
}
.px-10 {
  padding-left: 10px;
  padding-right: 10px;
}
.px-5 {
  padding-left: 5px;
  padding-right: 5px;
}
.pt-5 {
  padding-top: 5px;
}
.pr-25 {
  padding-right: 25px;
}
/*-----------------------------*/
/*           spacing           */
/*-----------------------------*/

/*-----------------------------*/
/*        Box Shadow           */
/*-----------------------------*/

.shadow-1 {
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

/*-----------------------------*/
/*        Box Shadow           */
/*-----------------------------*/
/*-----------------------------*/
/*        Text Align           */
/*-----------------------------*/

.text-center {
  text-align: center;
}
.text-right {
  text-align: right;
}
.text-left {
  text-align: left;
}

.item-center {
  align-items: center;
}
/*-----------------------------*/
/*        Text Align           */
/*-----------------------------*/

// #components-layout-demo-side .logo {
//   height: 32px;
//   margin: 16px;
//   background: rgba(255, 255, 255, 0.3);
// }

/*-----------------------------*/
/*          Ant Layout         */
/*-----------------------------*/
.ant-layout {
  height: 100vh;
  .ant-layout-sider {
    height: 100vh;
    background-color: var(--color-black-2);
    overflow: auto;
    .ant-layout-sider-children {
      height: 100vh;
      // height: calc(100vh - 48px);
      padding: 27px 10px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      label {
        color: var(--color-slate-3);
        font-family: montserrat600;
      }
      .side-menu-section {
        .logo {
          display: block;
          margin: 0 auto 1.5rem;
          width: 70%;
        }
        .ant-menu {
          .ant-menu-item {
            color: var(--color-text);
            svg {
              color: var(--color-text);
            }
            .trailerIcon {
              transform: rotateY(180deg);
            }
          }
          .ant-menu-item-selected {
            color: var(--color-white);
            svg {
              color: var(--color-white);
            }
            .yardmoveIcon,
            .trailerIcon {
              svg {
                g {
                  fill: var(--color-white);
                }
              }
            }
          }
        }
      }
      .userRow {
        margin-top: 1rem 0 0;
        .custom-avatar {
          width: 38px;
          height: 38px;
          border-radius: 50%;
          background: var(--color-gray);
          color: #fff;
          display: flex;
          justify-content: center;
          align-items: center;
          font-weight: 700;
          font-size: 16px;
        }
        .nameSection {
          width: max-content;
          h5 {
            color: var(--color-gray);
          }
          p {
            color: var(--color-white);
          }
        }
      }
      .logout-btn {
        width: 3rem;
        margin: 0 auto;
        cursor: pointer;
      }
      .ant-menu-dark {
        background-color: transparent;
        font-size: 1rem;
        font-family: montserrat500;
        color: var(--color-primary);
        .ant-menu-item {
          color: var(--color-text);
          // padding: 0 calc(50% - 17%);
          display: flex;
          align-items: center;
          border-radius: 4px;
          .ant-menu-item-icon {
            font-size: 1.5rem;
            line-height: 1rem;
          }
          .icon-img {
            width: 24px;
          }
        }
        .ant-menu-item-selected {
          // background-color: var(--color-white);
          color: var(--color-black);
        }
      }
      .ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal)
        .ant-menu-item-selected {
        background-color: var(--color-black);
      }
      .ant-menu-dark {
        .ant-menu-item-selected {
          .ant-menu-item-icon,
          .ant-menu-dark,
          .ant-menu-item-selected,
          span {
            color: var(--color-white);
          }
        }
      }
    }
    .ant-layout-sider-trigger {
      display: none;
    }
  }
  .site-layout {
    overflow: hidden;
    .ant-layout-content {
      height: 100vh;
      padding: 0 18px;
      overflow: hidden;
      @media only screen and (max-width: 1024px) {
        padding: 0 12px;
      }
      .sticky-layout-header {
        height: max-content;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 12px 0;
      }
      // .title {
      //   font-size: 3.5rem;
      //   color: var(--color-primary);
      //   font-family: montserrat700;
      // }
      // .dtl {
      //   font-size: 2rem;
      //   color: var(--color-light);
      //   font-family: montserrat500;
      // }
      // .ant-breadcrumb {
      //   margin: 8px 0;
      //   color: var(--color-info-40);
      //   li:last-child {
      //     color: var(--color-success-dark);
      //   }
      // }
      .site-layout-background {
        // height: calc(100vh - 114px);
        height: calc(100vh - 46px);
        overflow-y: scroll;
        scrollbar-width: none ;
        padding-bottom: 27px;
        &::-webkit-scrollbar {
          display: none;
        }
      }
    }
  }
  .ant-layout-sider-collapsed {
    .ant-layout-sider-children {
      padding: 27px 6px;
    }

    .side-menu-section {
      .logo {
        width: 100% !important;
      }
    }
    .custom-avatar {
      margin: 0 auto;
    }
    .userGrid {
      display: none;
      position: relative;
    }
    .sider-logout-btn {
      padding: 0;
      :nth-child(2) {
        display: none;
      }
      .menu-icon {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        width: 100%;
      }
    }
    .ant-menu-item {
      padding: 0 calc(50% - 11px);
      .icon-img {
        width: 24px;
        min-width: unset;
      }
    }
  }
}

/*-----------------------------*/
/*          ant Table          */
/*-----------------------------*/

.ant-table-wrapper {
  border: 1px solid var(--color-slate-3);
  border-radius: 12px;
  background-color: var(--color-white);
  // margin-bottom: 10px;
  .ant-spin-nested-loading {
    .ant-spin-container {
      .ant-table {
        background-color: transparent !important;
        .ant-table-container {
          border-top-right-radius: 8px;
          table {
            colgroup {
              background-color: transparent;
            }
            .ant-table-thead {
              tr {
                th {
                  border-top: 1px solid var(--color-slate-3);
                  font-size: 1rem;
                  color: var(--color-info-60);
                  font-family: montserrat500;
                  background: #f7f8f9;
                  padding: 8px;
                  &:first-child {
                    border-top-left-radius: 12px;
                    // text-align: center;
                  }
                  &:last-child {
                    border-top-right-radius: 12px;
                  }
                }
              }
              .ant-table-column-sorter-inner {
                color: #979797;
              }
              .ant-table-column-sorter-up.active {
                color: var(--color-primary);
              }
              .ant-table-column-sorter-down.active {
                color: var(--color-primary);
              }
            }
            .ant-table-tbody {
              .ant-table-row {
                .ant-table-cell {
                  padding: 8px;
                  font-family: montserrat500;
                  color: var(--color-info-60);
                  &:nth-child(1) {
                    color: var(--color-dark);
                    // text-align: center;
                  }
                  &.lineItemID {
                    visibility: hidden;
                  }
                  &.documentID {
                    visibility: hidden;
                  }
                }
              }
            }
          }
        }
      }
      .ant-table-sticky-holder {
        background-color: transparent !important;
      }
      .ant-pagination {
        padding: 0 1rem;
        margin: 10px 0;
        .ant-pagination-item {
          display: inline-block;
          min-width: 32px;
          height: 32px;
          margin-right: 8px;
          line-height: 30px;
          background-color: var(--color-slate-1);
          border: none;
          border-radius: 4px;
          a {
            color: var(--color-black-2);
            font-family: montserrat500;
          }
        }
        .ant-pagination-item-active {
          background-color: var(--color-black-2);
          a {
            color: var(--color-white);
          }
        }
        .ant-pagination-options {
          .ant-select {
            width: 110px;
            .ant-select-selector {
              background-color: var(--color-slate-1);
              border-radius: 4px;
              border: none;
              height: 32px;
              .ant-select-selection-item {
                line-height: 31px;
                font-size: 14px;
              }
            }
          }
        }
      }
    }
    .ant-pagination-next {
      &:hover {
        .ant-pagination-item-link {
          color: var(--color-black);
        }
      }
    }
    .ant-pagination-jump-next-custom-icon {
      .ant-pagination-item-container {
        .ant-pagination-item-link-icon {
          color: var(--color-black);
        }
      }
    }
    .ant-pagination-prev {
      &:hover {
        .ant-pagination-item-link {
          color: var(--color-black);
        }
      }
    }
    .ant-pagination-jump-prev-custom-icon {
      .ant-pagination-item-container {
        .ant-pagination-item-link-icon {
          color: var(--color-black);
        }
      }
    }
    .ant-pagination-options-quick-jumper input:hover {
      border-color: var(--color-black-2);
    }
  }
}
.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  background-color: var(--color-black-2);
  color: var(--color-white);
}
/*-----------------------------*/
/*            Input            */
/*-----------------------------*/
.ant-input:hover {
  border-color: var(--color-black);
}
.ant-input:focus {
  border-color: var(--color-black);
  box-shadow: 0 0 0 2px rgb(34 34 34 / 20%);
}
.ant-form {
  .ant-form-item {
    margin-bottom: 14px;
    .ant-form-item-row {
      .ant-form-item-label {
        padding: 0;
        label {
          color: var(--color-text-2);
          font-size: 1rem;
          font-family: montserrat500;
        }
      }
      .ant-form-item-control {
        .ant-form-item-control-input-content {
          .ant-input {
            padding: 9px 13px;
            color: var(--color-dark);
            font-size: 14px;
            line-height: 1.5715;
            // background-color: var(--color-white);
            border-radius: 8px;
            &:focus {
              // border-color: var(--color-black);
              // box-shadow: 0 0 0 2px rgb(34 34 34 / 20%);
            }

            &:hover {
              // border-color: var(--color-black);
            }
          }
          .ant-input-status-error {
            &:hover {
              // border-color: var(--color-error);
              // box-shadow: 0 0 0 2px rgb(255 77 79 / 20%);
            }
          }
          .ant-input-affix-wrapper {
            padding: 0px 11px;
            color: var(--color-dark);
            font-size: 14px;
            background-color: #fff;
            // border: 1px solid var(--color-info);
            border-radius: 6px;
            // border-color: red;
            .ant-input {
              padding: 9px 0px;
              border: none;
            }
            &:hover {
              // border-color: var(--color-black-2) ;
              box-shadow: none;
            }
            &:focus {
              // border-color: var(--color-black-2);
              // box-shadow: 0 0 0 2px rgb(34 34 34 / 20%);
            }
          }
          .ant-input-affix-wrapper-status-error {
            // border-color:var(--color-error);
          }
        }
        .ant-checkbox-wrapper {
          margin-top: 10px;
        }
      }
    }
    .ant-select {
      // color: var(--color-dark);
      font-size: 1rem;
      // background-color: var(--color-white);
      border-radius: 8px;
    }
    .ant-select-single:not(.ant-select-customize-input) //:not(.ant-select-show-search)
      .ant-select-selector {
      width: 100%;
      // height: 42px;
      padding: 0 11px;
      .ant-select-selection-search-input {
        //by zaid for select search
        // height: 42px;
      }
    }
  }
}

.search-input {
  border-radius: 8px;
  // height: 42px;
}
.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
  // border-color: var(--color-black-2);
  // box-shadow: none;
}

.ant-input-affix-wrapper-focused,
.ant-input-affix-wrapper:focus {
  // border-color: var(--color-black-2);
  // box-shadow: 0 0 0 2px rgb(34 34 34 / 20%);
}
.ant-select:not(.ant-select-disabled):hover .ant-select-selector {
  // border-color: var(--color-black-2);
}
.ant-select-focused:not(.ant-select-disabled).ant-select:not(
    .ant-select-customize-input
  )
  .ant-select-selector {
  // border-color: var(--color-black-2);
  // box-shadow: 0 0 0 2px rgb(34 34 34 / 20%);
}
.ant-form
  .ant-form-item
  .ant-form-item-row
  .ant-form-item-control
  .ant-checkbox-wrapper {
  .ant-checkbox {
    border-color: var(--color-gray-2);
    font-size: 18px;
    + span {
      color: var(--color-gray-2);
    }
    &:hover {
      color: var(--color-black-2);
    }
    .ant-checkbox-inner {
      width: 18px;
      height: 18px;
      &:after {
        width: 6px;
        height: 10px;
      }
    }
  }
}
.ant-checkbox-input:focus + .ant-checkbox-inner,
.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner {
  // border-color: var(--color-black-2);
}
.ant-picker:hover {
  // border-color: var(--color-black-2);
}
.ant-picker-focused {
  //   border-color: var(--color-black-2);
  // box-shadow: 0 0 0 2px rgb(34 34 34 / 20%);
}
// .ant-checkbox{
//   border-color:
// }

.ant-picker.ant-picker-disabled{
  & input{
    color: var(--color-dark);
  }
}


/*-----------------------------*/
/*            Input            */
/*-----------------------------*/
/*-----------------------------*/
/*            Select           */
/*-----------------------------*/
.ant-select:not(.ant-select-auto-complete) {
  color: var(--color-dark);
  font-size: 1rem;
  line-height: 1.5715;
  list-style: none;
  cursor: pointer;
  width: 100%;
}
.ant-select-single:not(.ant-select-auto-complete) {
  .ant-select-selector {
    .ant-select-selection-item {
      padding: 0;
      color: var(--color-dark);
      // line-height: 42px;
    }
    // .ant-select-selection-placeholder {
    //   line-height: 42px;
    // }
  }
}
.ant-select:not(.ant-select-customize-input):not(.ant-select-auto-complete)
  .ant-select-selector {
  // border: 1px solid var(--color-slate-3);
  border-radius: 8px;
}
.ant-select-single:not(.ant-select-customize-input) //:not(.ant-select-show-search)
  .ant-select-selector {
  width: 100%;
  // height: 42px;
  padding: 0 14px;
}

/*------Filter Select------*/
// .filter-select {
// display: flex;
// align-items: center;
// border: 1px solid var(--color-info);
// padding: 0 10px;
// border-radius: 8px;
// @media only screen and (max-width: 1360px) {
//   padding: 0 4px;
// }
// img {
//   width: 20px;
//   @media only screen and (max-width: 1300px) {
//     display: none;
//   }
// }
// .anticon-user{
//   @media only screen and (max-width: 1300px) {
//     display: none;
//   }

// }
// .ant-select {
// color: var(--color-dark);
// font-size: 14px;
// font-family: montserrat500;
// line-height: 31px;
// min-width: 120px;
// max-width: max-content;
// .ant-select-selector {
//   height: 31px !important;
//   padding: 0 8px !important;
//   border-radius: 0;
//   border: none;
//   background-color: transparent;
//   box-shadow: none;
//   .ant-select-selection-item {
//     line-height: unset;
//     padding-right: 18px;
//     padding-left: 4px;
//   }
//   .ant-select-selection-placeholder {
//     color: var(--color-dark);
//     font-weight: 500;
//     padding-left: 4px;
//     line-height: unset;
//   }
// }
// .ant-select-arrow {
//   color: var(--color-primary);
//   height: 16px;
//   width: 16px;
//   background-color: #f0f2f5;
//   font-size: 16px;
//   right: 4px;
//   margin-top: -9px;
// }
// .ant-select-clear {
//   height: 16px;
//   width: 16px;
//   background-color: #8ec5f9;
//   color: var(--color-primary-dark);
//   padding: 1px;
//   font-size: 14px;
//   border-radius: 1px;
//   right: 4px;
//   margin-top: -9px;
// }
// }
// .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input)
//   .ant-select-selector {
//   border-color: unset;
//   box-shadow: none;
// }
// }
/*-----------------------------*/
/*            Select           */
/*-----------------------------*/
/*-----------------------------*/
/*           Top Tabs          */
/*-----------------------------*/
.ant-tabs-top {
  height: calc(100vh - 84px);
  ::-webkit-scrollbar {
    display: none;
  }
  .ant-tabs-nav {
    margin-bottom: 0;
    &::before {
      border-bottom: 1px solid var(--color-text-3);
    }
    .ant-tabs-nav-list {
      .ant-tabs-tab {
        padding: 4px 0;
        color: var(--color-text-3);
        font-family: montserrat500;
        .ant-tabs-tab-btn {
        }
      }
    }
  }
  .ant-tabs-content {
    height: calc(100vh - 114px);
    .ant-tabs-tabpane {
      height: calc(100vh - 114px);
      // height: 100%;
      padding-top: 14px;
      padding-bottom: 14px;
      overflow-y: scroll;
      scrollbar-width: none ;
    }
  }
}
/*-----------------------------*/
/*           Top Tabs          */
/*-----------------------------*/
/*-----------------------------*/
/*            Drawer           */
/*-----------------------------*/

.ant-modal-wrap {
  .ant-modal {
    background-color: var(--color-slate-2);
    border-radius: 8px;
    .ant-modal-content {
      box-shadow: none;
      background-color: transparent;
      .ant-modal-header {
        background-color: transparent;
        // padding: 0 24px 24px;
      }
      .ant-modal-body {
        background: transparent;
      }
      .ant-modal-footer {
        background: transparent;
        padding-top: 0;
        margin-top: 5%;
        .ant-btn-primary {
          display: none;
        }
      }
    }
  }
}

/*-----------------------------*/
/*            Drawer           */
/*-----------------------------*/
/*-----------------------------*/
/*         Ant Message         */
/*-----------------------------*/
.ant-message {
  .msg-success {
    .ant-message-notice-content {
      border-radius: 8px;
      border: 1px solid var(--color-success);
      box-shadow: 0px 12px 16px -4px rgba(0, 0, 0, 0.25);
      .ant-message-success {
        display: flex;
        .anticon {
          top: 4px;
        }
      }
    }
  }
  .msg-error {
    .ant-message-notice-content {
      border-radius: 8px;
      border: 1px solid var(--color-danger);
      box-shadow: 0px 12px 16px -4px rgba(0, 0, 0, 0.25);
      .ant-message-error {
        display: flex;
        .anticon {
          top: 4px;
        }
      }
    }
  }
}
/*-----------------------------*/
/*         Ant Message         */
/*-----------------------------*/
/*-----------------------------*/
/*       Ant App Button       */
/*-----------------------------*/
.app-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  .anticon {
    font-size: 20px;
    line-height: 0;
  }
}

.btn_green {
  color: var(--color-white) !important;
  background-color: var(--color-black-2) !important;
  border: 1px solid var(--color-black-2) !important;
  &:hover {
    border: 1px solid var(--color-black) !important;
    background-color: var(--color-black) !important;
  }
}

.btn_red {
  color: var(--color-black) !important ;
  background-color: transparent !important;
  border: 1px solid var(--color-black) !important;
  &:hover {
    background-color: var(--color-slate-1) !important;
  }
}

.btn_dark {
  background-color: var(--color-dark-80) !important;
  color: var(--color-white) !important;
  border: 1px solid var(--color-dark-80) !important;
  width: max-content !important;
  border-radius: 4px !important;
  &:hover {
    color: var(--color-info) !important;
  }
  &:active {
    color: var(--color-white) !important;
  }
}
.btn_error {
  background-color: var(--color-danger) !important;
  color: var(--color-white) !important;
  border: 1px solid var(--color-danger) !important;
  width: max-content !important;
  border-radius: 4px !important;
  &:hover {
    color: var(--color-white) !important;
  }
  &:active {
    color: var(--color-white) !important;
  }
}
.btn_outlined {
  overflow: unset !important;
  background-color: transparent !important;
  color: var(--color-dark-80) !important;
  border: 1px solid var(--color-dark) !important;
  width: max-content !important;
  border-radius: 4px !important;
  font-family: montserrat600;
  &:hover {
    color: var(--color-dark) !important;
  }
  &:active {
    color: var(--color-dark-80) !important;
  }
}

// .ant-btn[disabled]{ 
//   background-color: #353535 !important;
//   span{
//     color: #9c9c9c !important;
//   }
// }



/*-----------------------------*/
/*       Ant App Button       */
/*-----------------------------*/
/*-----------------------------*/
/*           Badge             */
/*-----------------------------*/
.ant-badge {
  .ant-badge-count {
    border-radius: 0;
    padding: 0 4px;
    box-shadow: none;
  }
}
/*-----------------------------*/
/*           Badge             */
/*-----------------------------*/
/*-----------------------------*/
/*           Switch            */
/*-----------------------------*/
.ant-switch {
  background-color: var(--color-white);
  .ant-switch-handle {
    &::before {
      background-color: var(--color-primary-dark);
    }
  }
}
.ant-switch-checked {
  background-color: var(--color-white);
  .ant-switch-handle {
    &::before {
      background-color: var(--color-success);
    }
  }
}
/*-----------------------------*/
/*           Switch            */
/*-----------------------------*/
/*-----------------------------*/
/*           Users             */
/*-----------------------------*/

.users-tbl-action-row {
  .users-tbl-switch {
    background-color: #d9d9d9;
    .ant-switch-handle {
      &::before {
        background-color: #000;
      }
    }
  }
  .ant-switch-checked {
    .ant-switch-handle {
      &::before {
        background-color: #10b981;
      }
    }
  }
}
.user-modal-input {
  border-radius: 8px;
}
.users-tbl-icons {
  &:hover {
    transform: translateY(0.1rem);
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.129);
  }
}
/*-----------------------------*/
/*           Users             */
/*-----------------------------*/
/*-----------------------------*/
/*          Modal              */
/*-----------------------------*/

.ant-modal {
  .ant-modal-content {
    // .ant-modal-close{
    //   top: 24px;
    //   right: 24px;
    //   .ant-modal-close-x{
    //     height: 30px;
    //     width: 30px;
    //     border-radius: 100%;
    //     color: #fff;
    //     background-color: #000;
    //     line-height: 30px;
    //   }
    // }
    .ant-modal-header {
      padding: 24px;
    }
  }
}

/*-----------------------------*/
/*          Fab ICon           */
/*-----------------------------*/
.fabIcon {
  height: 22px;
  width: 22px;
  min-height: 22px;
  min-width: 22px;
  max-height: 22px;
  max-width: 22px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  background-color: #0162ea30;
  .icon {
    font-size: 14px;
    color: var(--color-primary);
  }
}
/*-----------------------------*/
/*          Fab ICon           */
/*-----------------------------*/
/*-----------------------------*/
/*          Divider            */
/*-----------------------------*/
.divider {
  margin: 5px auto;
}

/*-----------------------------*/
/*          Divider            */
/*-----------------------------*/
/*-----------------------------*/
/*          Modal              */
/*-----------------------------*/

.yard-move-clone-row {
  align-items: center;
  .yard-move-tab-filters {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
  }

  .yard-move-add-customer-btn,
  .yard-move-add-trailer-btn {
    img {
      display: none;
    }
    @media only screen and (max-width: 1400px) {
      span {
        display: none;
      }
      img {
        display: block;
      }
    }
  }
}
.add-customer {
  .ant-input-affix-wrapper {
    border-radius: 8px;
  }
}
/* Hide scrollbar for Chrome, Safari and Opera */
.example::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.example {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.customMarker {
  display: flex !important;
  justify-content: center;
  align-items: center;
  padding: 20px !important;
  border: 1px dashed #fff;
  font-weight: bold;
  background: #111827db !important;
  border-radius: 50%;
  color: #fff;
  z-index: 999;
  &::after {
    width: 100%;
    position: relative;
  }
}

.divider {
  margin: 5px auto;
  height: 3px;
  border-radius: 10px;
  background-color: var(--color-dark);
  opacity: 1;
  width: 200px;
}

/*-----------------------------*/
/*         Antd Upload         */
/*-----------------------------*/

.ant-upload.ant-upload-disabled {
  p {
    color: var(--color-dark);
  }
}

.ant-table-tbody > tr.ant-table-row-selected > td{
  background: transparent;
}
.ant-table-tbody > tr.ant-table-row-selected:hover > td {
  background: #fafafa;
}