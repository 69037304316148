.notifyCard {
  padding: 0.62rem 0.75rem;
  border: 1px solid var(--color-slate-3);
  border-radius: 8px;
  .content {
    display: flex;
    align-items: center;
    .divider {
      background-color: var(--color-info-40);
      height: 20px;
      margin: 0 1.25rem;
    }
  }
}

.upcomingCard {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 75%;
  max-width: 75%;
  padding: 25px;
  border-radius: 8px;
  text-align: center;
  img {
    width: 15%;
    @media only screen and (max-width: 1920px) {
      width: 25%;
    }
  }
  h4 {
    margin-top: 11px;
  }
  p {
    margin: 18px 0;
  }
}

/*-----------Document File---------*/
.documentFile {
  padding: 10px;
  background-color: var(--color-slate-1);
  border-radius: 8px;
  h6 {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .file {
    height: 137px;
    width: 100%;
    img {
      height: 100%;
      width: 100%;
    }
  }
  p {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
/*-----------Form Layout---------*/
.formLayout {
  position: relative;
  padding: 15px 13px 0;
  border-radius: 8px;
  border: 1px solid var(--color-slate-1);
  margin-bottom: 10px;
  .title {
    position: absolute;
    top: -8px;
    left: 45px;
    background-color: var(--color-slate-1);
    border-radius: 4px;
    padding: 0 13px;
    font-size: 12px;
    line-height: 16px;
    color: var(--color-dark);
    font-family: montserrat500;
  }
}
