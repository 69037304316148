/* -------------------------------------------------------------------------- */
/*                              scheduler styling                             */
/* -------------------------------------------------------------------------- */
.scheduler_default_main {
  border: 2px solid var(--color-slate-5);
  border-radius: 12px;
  overflow: hidden;

  .scheduler_default_divider_horizontal {
    border-top: 1px solid var(--color-slate-5) !important;
    border-bottom: 0px solid transparent !important;
  }
  .scheduler_default_timeheadercol_inner {
    border-right: 1px solid var(--color-slate-5) !important;
  }
  .scheduler_default_timeheadergroup_inner {
    border-right: 1px solid var(--color-slate-5) !important;
    border-bottom: 1px solid var(--color-slate-5) !important;
    font-size: 18px;
    font-family: Montserrat600;
    line-height: 24px;
  }
  .scheduler_default_resourcedivider {
    border-bottom: 1px solid var(--color-slate-5) !important;
  }
  .scheduler_default_divider,
  .scheduler_default_splitter {
    background-color: var(--color-slate-5);
  }
  .scheduler_default_timeheadergroup,
  .scheduler_default_timeheadercol {
    background-color: var(--color-white);
    background-color: var(--color-white);
  }
  .scheduler_default_rowheader,
  .scheduler_default_corner,
  .scheduler_default_cell {
    background-color: var(--color-white);
  }
}

.scheduler_default_event {
  font-size: 14px;
  font-family: Montserrat600;
  color: var(--color-dark);
  .scheduler_default_event_bar {
    display: none;
  }
  .scheduler_default_event_inner {
    border-radius: 2px;
    border-width: 2px;
  }
}
