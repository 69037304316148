.account {
  min-height: 100%;
  display: flex;
  flex-direction: column;
  // justify-content: space-between;
  .status {
    text-align: center;
    width: 100%;
    min-width: 90px;
  }
  .billHeighlight {
    height: max-content;
    border-top-right-radius: 6px;
    border-top-left-radius: 6px;
    border: 1px solid #d0d5dd;
    width: 45%;
    display: block;
    margin-left: auto;
    background-color: white;
    .space {
      width: 100%;
      justify-content: space-between;
      padding: 10px 46px;
    }
    .spaceDark {
      background-color: var(--color-dark);
    }
  }
  .invoiceHeigh {
    width: 55%;
  }
  .paidTag {
    padding: 5px 10px;
    border: 5px solid var(--color-success-dark);
    text-align: center;
    p {
      font-size: 60px;
      font-family: montserrat700;
      text-transform: uppercase;
      color: var(--color-success-dark);
      line-height: 73px;
    }
  }
  .invoiceTopBtns{
    position: absolute;
    right: 0;
    top: -38px;
  }
}
