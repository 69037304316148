.page_404 {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    .page_404_section {
      display: flex;
      flex-direction: column;
      align-items: center;
      .heading {
        transform: rotate(90deg);
        color: var(--color-black-2);
        margin: 0;
        font-weight: 900;
        font-size: 100px;
      }
      .pg1 {
        font-weight: 600;
        font-size: 35px;
      }
      .pg2 {
        font-weight: 500;
        font-size: 25px;
      }
    }
  }

  .page_overlay{
    position: absolute;
    z-index: 20;
    top: 50%;
    left: 50%;
    color: #fff;
    transform: translate(-50%, -50%);
    opacity: 95%;
    background: #222222f3;
    border-radius: 8px;
    width: 40%;
    height: 150px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .pg1 {
      font-weight: 600;
      font-size: 35px;
    }
    .pg2 {
      font-weight: 500;
      font-size: 25px;
    }
  }