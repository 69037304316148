.link {
    cursor: pointer;
    font-size: 14px;
    color: var(--color-text-4);
    font-family: montserrat500;
    text-align: center;
    margin-top: 2rem;
    span{
      font-weight: 500;
      color: var(--color-black-2);
      &:hover{
        font-weight: 700;

      }
    }
  }