.link {
    cursor: pointer;
    font-size: 14px;
    color: var(--color-dark);
    font-family: montserrat500;
    text-align: center;
    margin-top: 2rem;
    span{
      font-weight: 500;
      color: #46BFFF;
    }
  }