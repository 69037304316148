.tag{
  height: 30px;
  border-radius: 8px;
  padding: 4px 12px;
  font-size: 12px;
  font-family: montserrat500;  
}


/* -------------------------------------------------------------------------- */
/*                                 CHAT HEADER                                */
/* -------------------------------------------------------------------------- */
.chatHeader, .chatChanel{
  background-color: var(--color-tabCount);
  // padding: 10px;
  display: flex;
  width: calc(100% - 10px);
  margin: 5px;
  border-radius: 6px;
  justify-content: space-between;
  align-items: center;
  overflow: hidden;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  border: 1px solid var(--color-slate-1);
  .chatHeadBtn{
    margin: 3px;
    height: 30px;
    border-radius: 6;
    color: var(--color-black);
    background-color: transparent;
    font-size: 14px;
    font-weight: 600;
  }

  .chatHeadBtn.active{
    background-color: var(--color-black); //var(--color-slate-2);
    color: var(--color-white);

  }
  .backButtonIcon{
    width:24px;
    height:24px;
    background-color:#fff;
    padding:0px;
    &:hover{
      color: #000 ;
    }
    svg{
      font-size: 24px;

    }

  }
  .heading{
    display: flex;
    color: white;
    font-size: 16px;
    font-family: Montserrat700;
    align-items: center;
    svg{
      font-size: 24px;
    }
    p{
      padding-left: 5px;
    }
  }
}
.chatChanel{
 background-color: var(--color-slate-2);

  cursor: pointer;
  &:hover{
    background-color: #0162EA50;
  }
 .heading{
   color: var(--color-dark);
    font-size: 16px;
 }
 .messageCountBox{
   color: #fff;
   min-width: 30px;
   width: max-content;
   height: 30px;;
   padding: 2px;
   background-color: black;
   font-family: Montserrat600
 }
 
}