.authLayout {
  height: 100vh;
  width: 100vw;
  background-color: var(--color-white);
  overflow: hidden;
  .banner {
    background-image: url("../../Assets/images/AuthBanner.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    height: 100vh;
  }
  .content {
    padding: 2rem 5rem;
    height: 100vh;
    overflow: auto;
    @media only screen and (max-width: 1024px) {
      padding: 2rem ;
    }
    .logo {
      width: 60%;
      display: block;
      margin: 0 auto;
    }
    .items {
      margin-top: 3rem;
      
    }
  }
}
