
.btn{
    width: 100%;
    // background-color: var(--color-black-2);
    border-radius: 6px;
    overflow: hidden;
    text-overflow: ellipsis;
    border: none;
    transition: all 0.1s;
    cursor: pointer;
    color: var(--color-white);
    &:hover{
        // background-color: #000
    }
    &:active{
        // background-color: #000;
        transform: translateY(0.1rem);
        // box-shadow: 0 0.5rem 1rem rgba(34, 34, 34, .2);
    }
}